<template>
    <div>
        <div class="box">
			<h2 v-if="dashboard">{{ $t("compta.total_impaye") }}: {{ this.total_impaye }}€ - {{ $t("compta.all_currents_accounts") }}: {{ this.currents_accounts_balance }}€</h2>
            <CustomTable
                v-if="id_table"
                :id_table="id_table"
                :ref="id_table"
                :items="invoices"
                :busy.sync="table_busy"
                primaryKey="invoice_id"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
                :columsAddBegin="['preview']"
            >				
				<template v-slot:[`custom-slot-cell(invoice_exported)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.invoice_exported" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
				<template v-slot:[`custom-slot-cell(invoice_printed)`]="{ data }">
					<font-awesome-icon class="text-success" v-if="data.invoice_printed" :icon="['fal', 'check-circle']" />
					<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
				</template>
                <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
                    <a href="" class="" @click.prevent="quickPreview(data.invoice_id)">
					    <font-awesome-icon :icon="['fas', 'eye']" />
                    </a>
				</template>
                <template v-slot:custom-slot-cell(contracts)="{ data }">
                    <span v-for="(contract, idx) in data.contracts" class="d-flex align-items-center" :key="data.invoice_id+'_'+idx+'_'+contract.contract_id">
                        <router-link :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                            {{ contract.contract_num }}
                        </router-link>
                    </span>
                </template>
                <template v-slot:[`custom-slot-cell(tracking_envoie_date)`]="{ data }">
                    <span v-html="data.tracking_envoie_date"></span> 

                    <template v-if="data.mandrill_tracking">
                        <font-awesome-icon class="text-danger ml-1" :id="'tooltip-target-'+data.invoice_id" :icon="['fal', 'exclamation-triangle']"/>
                        <b-tooltip placement="right" :target="'tooltip-target-'+data.invoice_id" triggers="hover" style="min-width:500px; !important">
                            {{ data.mandrill_tracking }}
                        </b-tooltip>
                    </template>

                    <template v-if="data.mandrill_tracking.length == 0 && data.invoice_send_mail">
                       {{ $t('global.en_cours') }} <font-awesome-icon :icon="['far', 'hourglass']"/>
                    </template>
                </template>
                <template v-slot:custom-slot-cell(trackings_link)="{ data }">
                    <a href="" class="" @click.prevent="openTrackings(data.invoice_id)">{{ $t('global.see_trackings') }}</a>
                </template>
			</CustomTable>
        </div>

        <b-modal ref="modalPaymentInvoice" hide-footer size="xl">
            <template v-slot:modal-title>
                    {{ $t("payment.add_payment") }}
            </template>

            <AddPayment ref="addPaymentForm" :invoice_ids="invoice_ids" :invoice_accountingplans="invoice_accountingplans" :tiers_id="tiers_id" :invoice_nums="invoice_nums" :invoice_balances="invoice_balances" :processing.sync="processing" :ready.sync="ready" :submit_payment.sync="submit_payment"></AddPayment>

            <div class="d-none col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

        <b-modal size="xl" ref="modalAvoir" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("invoice.add_avoir") }}
            </template>

            <AddAvoir ref="addAvoir" :only_on_baf="only_on_baf" :facture_avoir="facture_avoir" :processing.sync="processing" :ready.sync="ready" :invoiceable.sync="invoiceable"></AddAvoir>

            <div class="col-4 m-auto">
                <b-button v-if="ready && invoiceable" block pill variant="primary" @click.prevent="submitAvoir"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider") }}</b-button>
                <b-button v-if="ready" block pill variant="primary" @click.prevent="submitAvoir(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider_en_baf") }}</b-button>
            </div>
        </b-modal>

        <ModalSendInvoice ref="modal_send_invoice" @submit="stopSpin"/>


        <b-modal size="xl" ref="modalTracking" hide-footer>
            <template v-slot:modal-title>
                {{ $t("invoice.tracking_email") }}
            </template>

            <Trackings :invoice_id="invoice_id" />
        </b-modal>
    </div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus';
    import Common from '@/assets/js/common.js'

    import Swiper from "GroomyRoot/components/Swiper";
    import HeaderTab from "@/components/HeaderTab";
    import CustomTable from 'GroomyRoot/components/Table/CustomTable'
    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35';
    import TableAction from "@/mixins/TableAction.js"

    import Navigation from "@/mixins/Navigation.js";
    import Tiers from "@/mixins/Tiers.js"
    import Payment from "@/mixins/Payment.js"
    import Invoice from "@/mixins/Invoice.js"
    import AddPayment from "@/components/Payment/AddPayment"
    import Tools from '@/mixins/Tools.js'

	export default {
		name: "Invoice",
		mixins: [Navigation, Tiers, Payment, TableAction, Invoice, Tools],
		data () {
			return {
				id_table: null,
                invoices: null,
                table_busy: true,
                modal_loaded: false,
                ready: false,
                invoiceable: true,
                processing: false,
                invoice_ids: null,
                invoice_nums: null,
                only_on_baf: false,
                invoice_balances: null,
                invoice_accountingplans: null,
                facture_avoir: {},
				total_impaye: 0,
                base64: null,
                currents_accounts_balance: 0,
                // rawColumnNames: ['phones_number'],
                // config_table_hrefs: {
                //     'contact_firstname': {
                        // routeUniqueName: 'tiersFiche',
                //         routeName: 'tiersFiche',
                //         params: {
                //             contact_firstname: 'contact_firstname'
                //         }
                //     }
                // }
                rawColumnNames: ['invoice_balance','invoice_ttc', 'tracking_envoie_date', 'received_date'],
				extern_slot_columns: ['invoice_exported', 'invoice_printed', 'preview', 'contracts', 'tracking_envoie_date', 'trackings_link'],
                events_tab: {
                    'TableAction::goToDuplicateInvoice': this.saveDuplicateInvoice,
                    'TableAction::goToDownloadPdfInvoice': (invoice_ids) => {
						this.downloadPdf(invoice_ids)
					},
                    'TableAction::goToPdfInvoice': (params) => {
                        this.pdfInvoice(params.invoice_ids, params.invoice_nums)
						.then(async () => {
							await this.$sync.force(true)
							this.init_component()
						})
                    },
                    'TableAction::goToPdfInvoiceWithAdvancedPayment': (params) => {
                        this.pdfInvoiceWithAdvancedPayment(params.invoice_ids, params.invoice_nums)
                        .then(async () => {
                            EventBus.$emit('TableAction::stopSpin')
							await this.$sync.force(true)
							this.init_component()
                        })
                    },
                    'TableAction::goToAddPayment': this.check_display_payment_form,
                    'TableAction::goToDeleteInvoice': (params) => {
                        this.goToDeleteInvoices(params).then(async () => {
                            this.table_busy = true
                            this.$refs[this.id_table].unselectAll()
                            await this.$sync.force(true)
                            this.loadInvoices()
                        })
                    },
                    'TableAction::goToExportComptable': this.goToExportComptable,
                    'TableAction::goToModalFactureAvoir': this.modalFactureAvoir,
                    'close_modal': this.closeAvoir,
                    'TableAction::goToSendInvoice': (params) => {
						let unique_ids = new Set(params.author_ids)
                        if(unique_ids.size == 1) {
							this.openModalSendInvoice(params.invoice_ids, unique_ids)
						}
						else {
                            this.infoToast("toast.model_choice")
							this.callSendInvoice(params.invoice_ids)
						}
                    },
					'TableAction::goToAccounted': (invoice_ids) => {
						this.accounted(invoice_ids)
					},
					'TableAction::goToNoPrint': (invoice_ids) => {
						this.no_print(invoice_ids)
					}
                },
                submit_payment: false,
                invoice_id: null
			}
		},
		props:['tiers_id', 'dashboard'],

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {
				if(this.dashboard) {
					this.id_table = "tiers_invoices_dashboard"
				}
                else {
                    this.id_table = "tiers_invoices"
                }

                if(this.tiers_id != 0){
                    this.table_busy = true
                    this.loadInvoices()
                }
            },

            async loadInvoices() {
				const invoices = await this.loadAllInvoicesByTiers(this.tiers_id)
                this.invoices = await this.formatInvoices(invoices)

                let total_impaye = 0
				if(this.dashboard) {
					this.invoices = this.invoices.filter(invoice => {
                        return Math.ceil(invoice.invoice_balance) != 0 && invoice.invoice_num
					});
                    for( const invoice of this.invoices){
                        total_impaye += (invoice.invoice_balance * 100)
                    }

                    // je dois avoir un id positif pour appeler l'API
                    if(this.tiers_id > 0) {
                        let currents_accounts_balance = 0
                        const currentsAccounts = await this.loadCurrentAccount(this.tiers_id)
                        Object.keys(currentsAccounts).forEach(cu => {
                            currents_accounts_balance += currentsAccounts[cu].balance
                        })
                        this.currents_accounts_balance = Math.round((currents_accounts_balance / 100) * 100) / 100
                    }
				}

                this.total_impaye = await this.priceFormat(total_impaye / 100)
                this.table_busy = false
            },

            async formatInvoices(invoices) {
                let invoicesFormated = []

                if(!invoices) {
                    return []
                }

                let date_lang_format = Vue.prototype.$i18n.locale()

                for (let i = 0; i < invoices.length; i++) {
					const temp = invoices[i]
                    const tracking_mail = temp.tracking.filter(tracking => tracking.invoicetracking_type == "mail")
                    temp.tracking_envoie_date = this.getArrayObjProperty(tracking_mail, "invoicetracking_date")

                    if(temp.tracking_envoie_date.length > 0) {
                        temp.tracking_envoie_date = temp.tracking_envoie_date.map(date => this.formatDate(date)).join('<br>')
                    }
                    
                    if(temp.tracking_envoie_date.length == 0){
                        temp.tracking_envoie_date = ""
                    }

                    const tracking_received = temp.tracking.filter(tracking => tracking.invoicetracking_type == "received")
                    temp.received_date = tracking_received.length > 0 ? tracking_received[tracking_received.length - 1].invoicetracking_date : ''

                    temp.mandrill_tracking = temp.mandrill_tracking ? temp.mandrill_tracking.map(tracking => {
                        return tracking.mandrilltracking_status+ ' : '+ tracking.mandrilltracking_error
                    }).join('<br>') : ''

                    // temp.invoice_ttc_vanilla = temp.invoice_ttc
                    // temp.invoice_ttc = await this.priceFormat(temp.invoice_ttc, temp.author.tiers_currency)
                    // temp.invoice_balance_vanilla = temp.invoice_balance
                    // temp.invoice_balance = await this.priceFormat(temp.invoice_balance, temp.author.tiers_currency)

                    temp.customClasses={
                        invoice_ttc:"text-right", 
                        invoice_balance:(temp.invoice_balance>0)?"text-right red":"text-right"
                    }

                    temp.contracts = []
                    if(temp.details) {
                        temp.details.forEach(detail => {
                            if(detail.contract && !temp.contracts.find(c => c.contract_id == detail.contract.contract_id)) {
                                temp.contracts.push({
                                    contract_id: detail.contract.contract_id,
                                    contract_num: detail.contract.contract_num
                                })
                            }
                        })
                    }

                    temp.invoice_renew = temp.invoice_renew && !temp.invoice_num ? this.getTrad('invoice.' + temp.invoice_renew) : ''

                    invoicesFormated.push(temp);
                }

                return invoicesFormated
            },
            async modalFactureAvoir(facture){
                this.facture_avoir = facture
                this.$refs['modalAvoir'].show()
            },
            async submitAvoir(only_baf = false){
                this.only_on_baf = only_baf
                await this.$refs['addAvoir'].submit()
            },
            async closeAvoir(){
                this.$refs['modalAvoir'].hide()
                this.table_busy = true
                await this.loadInvoices()
            },

            checkPaymentFormFunction(params){
                if(this.checkPaymentForm(params)) {
                    this.$refs['modalPaymentInvoice'].show()
                }
            },

            async onSubmit()
            {
                if(!this.processing)
                {
                    this.processing = true
                    let return_payment = await this.$refs['addPaymentForm'].addPayment()
                    if(return_payment) {
                        this.$refs[this.id_table].unselectAll()
                        this.$refs["modalPaymentInvoice"].hide()
                        this.table_busy = true
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "listPayment", val: true })
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "Invoice", val: true })
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "coordonneesTiers", val: true })
                        await this.$sync.force(true, true)
                        await this.loadInvoices()
                    }
                }
            },

            async downloadPdf(invoice_ids) {
				const platform = Common.getActualPlatformEvo()

				if(platform != "regular" && Array.isArray(invoice_ids) && invoice_ids.length > 1) {
					this.failureToast('invoice.fonction_indispo_plateforme')
					return
				}

				if(platform != "regular") {
					const resultPdf = await this.downloadPdfInvoice(invoice_ids)
					const base64Cleanedup = this.formatBase64String(resultPdf)

					this.previewPdf(base64Cleanedup, () => {
						EventBus.$emit('TableAction::stopSpin')
					})
				}
				else {
					this.downloadPdfInvoice(invoice_ids).then(() => {
						EventBus.$emit('TableAction::stopSpin')
					})
				}
			},

            async saveDuplicateInvoice(params) {
                await this.duplicateInvoice(params.invoice_ids)
                EventBus.$emit('TableAction::stopSpin')
            },

            goToExportComptable(params) {
                let invoice_ids = params.invoice_ids
                return this.loadExportComptable(invoice_ids.split(","))
            },

            async callSendInvoice(invoice_ids) {
                await this.sendMailInvoices(invoice_ids)
                EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
            },

			async accounted(invoice_ids) {
				this.accounted_invoices(invoice_ids)
				.then(() => {
					this.table_busy = true
					this.loadInvoices()
				})
			},

			no_print(invoice_ids) {
				this.notPrintedInvoice(invoice_ids)
				.then(() => {
					this.table_busy = true
					this.loadInvoices()
				})
			},

            openModalSendInvoice(invoice_ids, unique_author_id) {
				let author_id = unique_author_id.values().next().value
				this.$refs.modal_send_invoice.openModal(invoice_ids, author_id)
			},

			stopSpin() {
				EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
			},

            async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },

            openTrackings(invoice_id) {
                this.invoice_id = invoice_id
                this.$refs.modalTracking.show()
            }
		},
        watch: {
            submit_payment() {
                this.onSubmit()
            }
        },
		components: {
            Swiper,
            HeaderTab,
            CustomTable,
            LoadingSpinner,
            AddAvoir: () => import('@/components/Invoice/AddAvoir'),
            AddPayment,
            ModalSendInvoice : () => import('@/components/Tiers/ModalSendInvoice'),
            Trackings : () => import('@/components/Trackings')
		}
	}
</script>
